
.search-wrapper svg {
    position: absolute;
    left: 11px;
    top: 54%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    z-index: 1;
}
.main-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.42;
    color: var(--main-color);
}

.media-table-btn,
.upgrade-btn {
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5
}

.upgrade-btn {
    padding: 10px 17px;
    background: rgba(0, 97, 247, .1);
    font-style: normal;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    color: #5f2eea
}

.upgrade-btn:hover {
    color: #5f2eea;
    background: rgba(0, 97, 247, .15)
}

.upgrade-btn svg {
    margin-left: 10px
}

.media-table-btn {
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid #eee;
    color: #b9b9b9
}

.view-sort-btn {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    background-color: #eff0f6;
    color: #d6d7e3
}

.view-sort-btn:hover {
    color: #fff;
    background-color: #e0e3f1
}

.dropdown-btn {
    color: #b9b9b9
}

.link-info {
    color: #5887ff;
    border-bottom: dashed 1px transparent
}

.link-info:hover {
    color: #5887ff;
    border-bottom-color: #5887ff
}

.dropdown {
    position: absolute;
    padding: 5px 10px;
    background-color: #fff;
    /* -webkit-box-shadow: 0 -2px 24px rgba(160, 163, 189, .25);
    box-shadow: 0 -2px 24px rgba(160, 163, 189, .25); */
    border-radius: 4px;
    /* opacity: 0;
    visibility: hidden; */
    -webkit-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;
    z-index: 3
}

/* .dropdown::before {
    content: '';
    position: absolute;
    right: 6px;
    top: -10px;
    border-style: solid;
    border-width: 0 15px 15px;
    border-color: transparent transparent #fff
} */

.dropdown li:last-child a {
    border-width: 0
}


.dropdown a:hover {
    color: #2f49d1
}

.badge-active,
.badge-disabled,
.badge-pending,
.badge-success,
.badge-trashed {
    padding: 1px;
    width: 85px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.67;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 100px;
    text-align: center
}

.badge-active {
    color: #5887ff;
    background-color: rgba(88, 135, 255, .1)
}

.badge-pending {
    color: var(--main-color);
}

.badge-disabled {
    color: #fff;
    background-color: #ffb648
}

.badge-trashed {
    color: #f26464;
    background-color: rgba(245, 91, 93, .1)
}

.badge-success {
    color: #4bde97;
    background-color: rgba(75, 222, 151, .1)
}

input {
    border-radius: 5px;
    /* background-color: #eff0f6; */
    /* min-height: 40px; */
    border: solid transparent 2px !important
}

input[type=checkbox],
input[type=radio] {
    min-height: auto
}

input[type=radio] {
    width: 24px;
    height: 24px;
    margin-right: 19px
}

input[type=radio]:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-input::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #d6d7e3
}

.form-input::-moz-placeholder,
input::-moz-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #d6d7e3
}

input:-ms-input-placeholder,
input::-ms-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #d6d7e3
}

input::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #d6d7e3
}

input:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control:focus {
    box-shadow: none !important;
}

input:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-calendar-picker-indicator {
    display: none !important
}

.check {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

label {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.file-input-main {
    margin-bottom: 20px;
}

.folder_main_update {
    display: flex;
    align-items: center;
}

.folder_main_update img {
    margin-left: 10px;
    cursor: pointer;
}


.new-page-content input {
    padding: 5px 16px
}

.users-table table,
.users-table-info {
    line-height: 1.4;
    letter-spacing: -.3px;
    color: #767676
}

.users-table table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.users-table-info {
    height: 48px;
    border-bottom: 1.2px solid #dadbe4;
    font-size: 14px
}

.users-table-info th {
    font-weight: 500;
    padding: 10px;
}


.users-table__checkbox input {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    font-size: 33px;
}

.users-table td {
    background-color: #fff;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 8px 10px;
}

.users-table td:first-child {
    padding: 6px 5px;
}

.users-table tbody {
    font-size: 14px
}

.users-table tbody tr:last-child .users-item-dropdown {
    bottom: 250%;
    top: auto
}

.users-table tbody tr:last-child .users-item-dropdown::before {
    bottom: -10px;
    top: auto;
    border-width: 15px 15px 0;
    border-color: #fff transparent transparent
}

.users-table .users-item-dropdown {
    right: -9px;
    top: 150%
}

.table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px
}

.table-wrapper table {
    min-width: 850px
}

.table-wrapper table.posts-table {
    min-width: 944px
}

.table-wrapper table.library-table {
    min-width: 483px
}

/* .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: var(--main-color) !important;
    width: 320px;
    padding: 0;
    -webkit-transition: .5s all;
    -o-transition: .5s all;
    transition: .5s all;
    min-height: 100vh;
    z-index: 9;
} */

.sidebar a:focus {
    outline: 0
}

.sidebar-head {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, .1)
}



.sidebar-body-menu a,
.sidebar-user {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.sidebar-body-menu a {
    position: unset;
    padding: 4px 0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
}

.sidebar-body-menu a::after {
    position: absolute;
    content: '';
    height: 22px;
    width: 3px;
    right: -11px;
    border-radius: 10px;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all
}

.cat-sub-menu.visible a:hover,
.sidebar-body-menu a.active,
.sidebar-body-menu a.active::after,
.sidebar-body-menu a:hover,
.sidebar-body-menu a:hover::after {
    opacity: 1
}

.sidebar-user {
    border-radius: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, .1)
}

.sidebar-body-menu a,
.sidebar-user,
.sidebar-user-img,
.sidebar-user-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.sidebar-user-img {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-right: 8px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    overflow: hidden;
    background-color: #fff
}

.sidebar-user-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.sidebar-user__title {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: .75px;
    color: #fff
}

.sidebar-user__subtitle {
    font-weight: 500;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: .86px;
    color: rgba(255, 255, 255, .5)
}

.logo {
    margin-right: 9px
}

.logo-text,
.logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.logo-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.logo-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #fff
}

.logo-subtitle,
.logo-title {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: .86px
}

.logo-subtitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.42
}

.msg-counter,
.system-menu__title {
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: .75px
}

.msg-counter {
    position: absolute;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #f26464;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 12px !important;
    color: #fff
}

.system-menu__title {
    display: inline-block;
    margin-top: 13px;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(255, 255, 255, .5)
}

.cat-sub-menu {
    padding-left: 34px;
    height: 0
}

.cat-sub-menu.visible {
    height: auto
}

.cat-sub-menu.visible a {
    opacity: 1;
    visibility: visible
}

.cat-sub-menu a {
    opacity: 0;
    visibility: hidden
}

.category__btn .icon {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.main-nav {
    padding-top: 10px;
    padding-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-shadow: 0 2px 7px rgb(30 30 30 / 10%);
}

.main-nav a:focus {
    outline: 0
}

.main-nav--bg {
    -webkit-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
}

.main-nav-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.main-nav-end>* {
    margin-right: 15px
}


.main-nav .notification,
.main-nav .notification-wrapper {
    position: relative
}

.main-nav .notification::after {
    display: none;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    border: 1px solid #fff;
    background-color: #f26464
}

.main-nav .notification.active::after {
    display: initial
}

.main-nav .notification-dropdown {
    right: 0;
    width: 236px;
    padding: 10px
}

.main-nav .notification-dropdown-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 167px
}

.main-nav .notification-dropdown__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.22;
    color: #171717;
    margin-bottom: 5px
}

.main-nav .notification-dropdown__subtitle {
    font-size: 10px;
    line-height: 1.2;
    color: #767676
}

.main-nav .notification-dropdown-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%
}

.main-nav .notification-dropdown-icon.info {
    background-color: rgba(88, 135, 255, .1);
    color: #5887ff
}

.main-nav .notification-dropdown-icon.danger {
    background-color: rgba(242, 100, 100, .1);
    color: #f26464
}

.main-nav .notification-dropdown a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 15px
}

.main-nav .notification-dropdown a.link-to-page {
    padding-top: 17px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #5887ff
}

.main-nav .nav-user-wrapper,
.search-wrapper {
    position: relative
}

.main-nav .nav-user-wrapper .users-item-dropdown {
    width: 171px;
    right: 0
}

.main-nav .nav-user-wrapper .users-item-dropdown svg {
    margin-right: 13px
}

.main-nav .nav-user-img,
.main-nav .nav-user-img picture,
.main-nav .nav-user-wrapper .users-item-dropdown a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.main-nav .nav-user-wrapper .users-item-dropdown a.danger {
    color: #f26464
}

.main-nav .nav-user-img {
    border-radius: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
}

.main-nav .nav-user-img picture {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.search-wrapper input {
    width: 271px;
    border-radius: 6px;
    background-color: #fff;
    padding: 3px 8px 3px 42px;
    height: 40px;
    border-width: 0;
    border: 1px solid #ccc !important;
}

.search-wrapper input::-webkit-input-placeholder {
    background-color: #fff;
    font-size: 14px;
    line-height: 2.43;
    color: var(--main-color);
    -webkit-transition: .3s all;
    transition: .3s all
}

.search-wrapper input::-moz-placeholder {
    background-color: #fff;
    font-size: 14px;
    line-height: 2.43;
    color: var(--main-color);
    -moz-transition: .3s all;
    transition: .3s all
}

.search-wrapper input:-ms-input-placeholder,
.search-wrapper input::-ms-input-placeholder {
    background-color: #fff;
    font-size: 14px;
    line-height: 2.43;
    color: var(--main-color);
    -ms-transition: .3s all;
    transition: .3s all
}

.search-wrapper input::placeholder {
    background-color: #fff;
    font-size: 14px;
    line-height: 2.43;
    color: var(--main-color);
    -webkit-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all
}

.lang-switcher {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1875;
    letter-spacing: .75px;
    color: #767676
}

.lang-switcher-wrapper {
    position: relative
}

.lang-menu {
    top: 40px;
    right: 0;
    width: 125px
}

.lang-menu.active {
    opacity: 1;
    visibility: visible
}

.sort-bar {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 36px 20px 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 0 #dadbe4;
    box-shadow: 0 1px 0 #dadbe4;
    border-radius: 10px 10px 0 0
}

.sort-bar .search-wrapper {
    position: relative;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.sort-bar .search-wrapper input {
    padding: 5px 42px 5px 16px
}

.sort-bar .search-wrapper svg {
    left: auto;
    right: 16px
}

.sort-bar .select {
    min-width: 185px;
    margin-right: 20px
}

.sort-bar .select.small {
    min-width: 142px
}

.sort-bar .filter-btn {
    min-width: 106px
}

.sort-bar,
.sort-bar-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.categories-sort-bar .sort-bar-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.stat-cards {
    margin-right: -10px;
    margin-left: -10px
}

.stat-cards>div {
    padding-left: 10px;
    padding-right: 10px
}

.stat-cards-icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 9px
}

.stat-cards-icon.primary {
    color: #2f49d1
}

.stat-cards-icon.warning {
    background-color: rgba(255, 182, 72, .1);
    color: #ffb648
}

.stat-cards-icon.purple {
    background-color: rgba(95, 46, 234, .1);
    color: #5f2eea
}

.stat-cards-icon.success {
    background-color: rgba(75, 222, 151, .1);
    color: #4bde97
}

.home,
.menu-toggle--gray {
    background-image: url(../public/img/Menu-toggle-gray.svg);
    width: 24px;
    height: 24px;
    display: block;
}

.arrow-down {
    background-image: url(../public/img/Arrow-DownCircle.svg);
}

.stat-cards-info__profit.success {
    color: #04AED2;
}

.stat-cards-info__profit.danger {
    color: #f26464
}

.stat-cards-info__profit.warning {
    color: #ffb648
}

.stat-cards-info__profit svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    border: 1px solid #04AED2;
    padding: 3px;
    border-radius: 5px;
}


.customers-wrapper {
    height: 256px;
    background-color: #5f2eea;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px
}

.customers-wrapper img {
    position: absolute;
    max-width: none;
    bottom: 0;
    left: -10px
}

.customers__title,
.top-cat-title h3 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px
}

.customers__date,
.customers__title {
    line-height: 1.4;
    letter-spacing: -.5px;
    color: #fff
}

.customers__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.customers__date {
    font-weight: 500;
    font-size: 14px;
    opacity: .5
}

.top-cat-title {
    margin-bottom: 10px
}

.top-cat-title h3 {
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -.4px;
    color: #171717
}

.top-cat-list__subtitle span,
.top-cat-title p {
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -.4px
}

.top-cat-title p {
    font-size: 12px;
    color: #b9b9b9
}

.top-cat-list a {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee
}

.top-cat-list__subtitle,
.top-cat-list__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: -.4px
}

.top-cat-list__title {
    font-weight: 600;
    color: #171717;
    margin-bottom: 4px
}

.top-cat-list__subtitle {
    color: #b9b9b9
}

.top-cat-list__subtitle span {
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 3px
}

.top-cat-list__subtitle .blue,
.top-cat-list__subtitle .purple {
    color: #5f2eea;
    background-color: rgba(116, 51, 255, .1)
}

.top-cat-list__subtitle .blue {
    color: #0061f7
}

.top-cat-list__subtitle .danger {
    color: #f26464;
    background-color: rgba(245, 91, 93, .1)
}

.top-cat-list__subtitle .success {
    color: #4bde97;
    background-color: rgba(75, 222, 151, .1)
}

.top-cat-list__subtitle .warning {
    color: #ffb648;
    background-color: rgba(255, 172, 50, .1)
}

.top-cat-list__subtitle .primary {
    color: #2f49d1;
    background-color: rgba(47, 73, 209, .1)
}

.chart {
    width: 100%;
    height: 370px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 15px 50px 0 rgba(160, 163, 189, .1);
    box-shadow: 0 15px 50px 0 rgba(160, 163, 189, .1)
}


.main {
    padding-top: 20px;
    padding-bottom: 20px;
}

.main-content {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 65vh
}

.main-title-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 34px
}

.main-title-wrapper h2 {
    margin-bottom: 0
}

.main-title-wrapper .primary-default-btn svg {
    margin-right: 9px
}

.main-btns-wrapper,
.main-title-wrapper,
.users-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.main-btns-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.main-btns-wrapper button {
    margin-right: 10px
}

.users .col-xl-3 {
    margin-bottom: 20px
}

.users-item {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    text-align: center
}

.users-item a:focus {
    outline: 0
}

.users-item-body {
    padding: 20px 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.users-item__img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px
}

.users-item__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: -.3px;
    color: #171717
}

.users-item__desc,
.users-item__role {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: -.3px;
    color: #767676
}

.users-item__desc {
    max-width: 231px;
    font-size: 12px;
    margin-bottom: 15px
}

.users-item__more {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: .5
}

.users-item-dropdown {
    right: 20px;
    top: 60px;
    width: 125px
}

.users-item-dropdown.active {
    opacity: 1;
    visibility: visible
}

.users-item .primary-white-btn {
    border-top: 1px solid #eee;
    width: 100%
}


.form {
    -webkit-box-shadow: 0 5px 10px rgba(160, 163, 189, .1);
    box-shadow: 0 5px 10px rgba(160, 163, 189, .1);
    border-radius: 10px;
    padding: 40px 40px 56px;
    background-color: #fff;
    text-align: left
}

.form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #171717;
    cursor: pointer;
    margin-bottom: 5px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.form-label-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
}

.form-input {
    border-radius: 8px;
    border-width: 0;
    margin-bottom: 15px;
    padding: 5px 16px;
    height: 44px;
    background: #eff0f6
}

.form-input:-ms-input-placeholder,
.form-input::-ms-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #d6d7e3
}

.form-input::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    color: #d6d7e3
}

.form-checkbox {
    margin-right: 12px
}

.form-checkbox-label {
    font-family: Inter;
    font-size: 14px;
    line-height: 1.71;
    color: #767676
}

.form-checkbox-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 20px
}

.form-btn {
    width: 100%
}

.forget-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 2.43;
    border-bottom: dashed 1px transparent
}

.forget-link:hover {
    color: #2f49d1;
    border-color: #2f49d1
}

.sign-up__subtitle,
.sign-up__title {
    text-align: center;
    margin-bottom: 20px
}

.sign-up__title {
    font-size: 32px;
    line-height: 1.06;
    letter-spacing: 1px;
    color: #171717
}

.sign-up__subtitle {
    max-width: 424px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: #767676
}

.categories-table-img {
    margin-right: 26px
}

.categories-table-img img {
    width: 71px;
    height: 47px;
    border-radius: 6px
}

.darkmode {
    background-color: #11111d
}

.darkmode .main-nav--bg {
    background-color: #161624
}

.darkmode .search-wrapper input {
    color: #d6d7e3;
    background-color: #222235
}

.darkmode .search-wrapper input::-webkit-input-placeholder {
    background-color: #222235
}

.darkmode .search-wrapper input::-moz-placeholder {
    background-color: #222235
}

.darkmode .search-wrapper input:-ms-input-placeholder,
.darkmode .search-wrapper input::-ms-input-placeholder {
    background-color: #222235
}

.darkmode .search-wrapper input::placeholder {
    background-color: #222235
}

.darkmode .gray-circle-btn {
    background-color: #222235
}

.darkmode .sun-icon,
.moon-icon {
    display: none
}

.darkmode .moon-icon {
    display: initial;
    fill: #fff
}

.darkmode .sidebar {
    background-color: #161624 !important
}

.darkmode .dropdown {
    background-color: #11111d;
    -webkit-box-shadow: none;
    box-shadow: none
}

.darkmode .dropdown::before {
    border-color: transparent transparent #11111d
}

.darkmode .dropdown a {
    border-bottom-color: #2c2c42;
    color: #d6d7e3
}

.darkmode .dropdown-btn {
    color: #b9b9b9
}

.darkmode .notification-dropdown__title {
    color: #d6d7e3
}

.darkmode .main-title {
    color: #eff0f6
}

.darkmode #myChart,
.darkmode .stat-cards-item {
    background-color: #222235;
    -webkit-box-shadow: none;
    box-shadow: none
}


.darkmode .chart {
    -webkit-box-shadow: none;
    box-shadow: none
}

.darkmode .sort-bar,
.darkmode .users-table thead {
    background-color: #161624
}

.darkmode .users-table {
    color: #eff0f6
}

.darkmode .users-table-info {
    border-bottom-color: transparent;
    color: #eff0f6
}

.darkmode .users-table td {
    background-color: #222235;
    border-color: transparent
}

.darkmode .users-table tbody tr:last-child .users-item-dropdown::before {
    border-color: #11111d transparent transparent
}

.darkmode .users-table.active td {
    background-color: #2c2c42
}

.darkmode .sort-btn {
    color: #d6d7e3
}

.darkmode .form {
    background-color: #161624
}

.darkmode .form-label {
    color: #d6d7e3
}

.darkmode .form-input {
    background-color: #222235;
    color: #d6d7e3
}

.darkmode .sign-up__title,
.darkmode .top-cat-title h3 {
    color: #eff0f6
}

.darkmode .sign-up__subtitle {
    color: #d6d7e3
}

.darkmode .new-page-content .ql-toolbar,
.darkmode .new-page-content input {
    background-color: #363648;
    color: #d6d7e3
}

.darkmode .white-block {
    background-color: #222235;
    color: #d6d7e3
}

.darkmode .white-block__title {
    color: #d6d7e3
}

.darkmode .white-block .radio {
    background-color: #37374b
}

.darkmode .main-content {
    background-color: #161624
}

.darkmode .top-cat-list a {
    border-color: #37374f
}

.darkmode .top-cat-list__title {
    color: #eff0f6
}

.darkmode .pagination a {
    opacity: .5;
    color: #fff
}

.darkmode .pagination a.active {
    opacity: 1
}

.darkmode .badge-disabled {
    background-color: rgba(255, 182, 72, .1);
    color: #ffb648
}

.darkmode .primary-white-btn {
    border: 1px solid #222235 !important;
    background-color: #161624
}

.darkmode .footer {
    border-top-color: #222235
}


/* =========main========= */
.sidebar-head {
    display: flex;
}

.sidebar-toggle:focus {
    outline: none;
    border: 0 !important;
}

.home_list_icon img {
    width: 18px;
}

.transparent-btn-main.rotated {
    top: inherit;
    background: #fff;
    color: #000;
    border-radius: 4px;
    padding: 2px 5px;
    align-items: baseline;
    display: block;
}

.icon_l img {
    width: 13px;
    float: right;
    line-height: 12;
    padding-top: 15px;
}

.transparent-btn-main.rotated .home_list_icon img {
    width: 20px;
}

.cat-sub-menu a:hover {
    color: var(--secondary-color);
}

.transparent-btn-main {
    display: block !important;
}

.transparent-btn-main .home_list_icon img {
    filter: brightness(0) invert(1);
}

.transparent-btn-main.rotated .home_list_icon img {
    filter: none;
}

.transparent-btn-main span .icon_l img {
    filter: brightness(0) invert(1);
}

.transparent-btn-main.rotated span .icon_l img {
    filter: none;
}



.main-nav-det h4 span img {
    width: 22px;
}

.main-nav-det h4 span {
    padding-right: 10px;
}

.input_box_section {
    margin-bottom: 15px;
}

.input_box_section label {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
}

.input_box_section label span {
    color: Red;
}

.input_box_section select {
    border: 1px solid #F3F4F6;
    padding: 10px;
    width: 100%;
    background-color: #F3F4F6;
    margin-top: 10px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
}

.input_box_section textarea {
    border: 1px solid #F3F4F6;
    padding: 5px 10px;
    width: 100%;
    background-color: #F3F4F6;
    margin-top: 10px;
    border-radius: 5px;
}

.input_box_section textarea {
    resize: none;
}

.input_box_section input::placeholder {
    color: #000;
    font-weight: 400;
}

.pb-5 {
    padding-bottom: 15px;
}

.input_box_section-new label {
    font-weight: normal;
    font-size: 14px;
    color: #333333;
}

.input_box_section select {
    border: 1px solid #F3F4F6;
    padding: 10px;
    width: 100%;
    background-color: #F3F4F6;
    margin-top: 10px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
}

.input_box_agreement {
    border-bottom: 1px solid #bcbcbc !important;
    padding: 5px 10px;
    width: 20%;
    background-color: transparent !important;
    outline: none;
    font-size: 12px;
    border-radius: 0 !important;
    font-weight: 400;
    min-height: 30px !important;
}

.input_box_agreement:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}


.alfa_listing ul {
    display: flex;
    text-align: center;
}

.alfa_listing ul li .input_box_section input {
    margin-top: 0;
}

.alfa_listing ul li .input_box_section input {
    text-align: center;
}

.alfa_listing ul li:nth-child(even) .input_box_section input {
    background-color: #fff !important;
    border: none !important;

}

.alfa_listing-kg ul {
    display: flex;
    text-align: center;
    padding-left: 0;
}

.alfa_listing-kg ul li {
    padding: 0 10px;
}

.alfa_listing-kg ul li .input_box_section input {
    margin-top: 0;
}

.w-swction_main select {
    width: 70px;
    margin-top: 0 !important;
}

.main_package_formar input {
    padding: 10px;
    width: 235px;
    text-align: center;
    background-color: #F3F4F6;
    margin-top: 10px;
    outline: none;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    color: #000;
    margin: 24px 0;
    cursor: pointer;
}

.main_package_formar input::placeholder {
    color: #000;
}

.main-nav-det-button {
    text-align: right;
    margin-top: 40px;
}

.main-nav-det-button a {
    padding: 11px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    background: var(--main-color);
    color: #000;
    background: #ccc;
}

.main-nav-det-button a:last-child {
    background: var(--main-color);
    color: #fff !important;
}

.main-nav-det-button a:hover {
    background: var(--secondary-color);
    color: #fff;
}

.related_to_main {
    padding: 20px 0;
}

.related_to_main h5 {
    display: flex;
    align-items: center;
}

.related_to_main span {
    padding-right: 10px;
}

.related_to_main span img {
    width: 25px;
}

.main_list_numvar {
    list-style: auto;
    padding-left: 16px;
}

.text-left {
    text-align: left;
}

.main_select_photo {
    padding: 15px 0;
}

.file-input>[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

/* .folder_main_bro {
    height: 50px;
    width: 200px;
    border-radius: 5px;
    background: #ffffff;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #1B7BB1;
    color: #1B7BB1;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.folder_main_bro span {
    padding-right: 10px;
}



.main_select_photo ul li {
    padding: 0 10px;
}

.por_main_detail {
    position: relative;
}

.por_main_detail span img {
    position: absolute;
    top: 46px;
    right: 15px;
}

.button-det-list {
    text-align: right;
}

.button-det-list a {
    padding: 11px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    background: var(--main-color);
    color: #fff;
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
}

/*=============Search bar=============*/


.input_box_filet input::placeholder {
    color: #ccc;
}

.filter_icon {
    position: relative;
}

.filter_icon span img {
    position: absolute;
    top: 13px;
    left: 10px;
}

.input_box_id_button a {
    padding: 11px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    background: var(--main-color);
    color: #000;
    background: #f4f4f4;
}

.main_row {
    align-items: baseline;
}

.por_main_detail-date {
    position: relative;
}

.por_main_detail-date span img {
    position: absolute;
    top: 11px;
    right: 15px;
}

.por_main_detail-date input {
    padding: 12px 10px;
    border-radius: 8px;
}

.main_detail_filter {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

/* =============Search bar============= */
/* ========button listing========= */
.main_listing_button {
    display: block;
    padding: 15px 0;
}

.main_listing_button ul li a {
    padding: 7px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: normal;
    margin: 5px 5px;
}

.dropdown-filter-section {
    position: unset;
    opacity: 1;
    visibility: unset;
    padding: 0;
    box-shadow: none !important;
}


.dropdown-filter-section::before {
    border-color: transparent transparent transparent;
}

.main_drop_filter {
    padding: 0;
}

.main_drop_filter {
    width: 100%;
    min-width: 300px;
}

.main_drop_filter .content-table {
    width: 100%;
}

.main_drop_filter .content-table tbody tr {
    border-bottom: 1px solid #04aed2;
}

.por_main_detail-date input::placeholder {
    color: #5E57D8 !important;
}
.input_box_filter input:focus {
    box-shadow: none;
}

.content-table tr td {
    font-size: 12px;
    font-weight: bold;
}

.content-table td {
    border: 1px solid #dddddd;
}

.main_drop_filter a {
    color: #fff !important;
    background-color: var(--main-color);
    border-radius: 10px;
    display: block;
    padding: 0 10px;
    margin: 10px 10px 10px auto;
    width: 60px;
    height: 35px;
}
.main_drop_filter a:hover {
    background-color: var(--secondary-color);
}

/* =========button listing========== */
/* =======listing detail========== */
.main_listing_detail {
    padding: 10px;
    background: #FAF6E6;
    margin: 10px 0;
}

.users-table-info th {
    white-space: nowrap;
}

.users-table-info {
    background-color: #F2F2F2;
}

.view_button {
    padding: 7px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: normal;
}

.scroll_listing_detail {
    padding: 10px;
    background: #FAF6E6;
    margin: 10px 0;

}

.scroll_listing_detail .users-table {
    overflow-x: auto;
    width: 100%;
    /* max-width: 1200px; */
}

.users-table td {
    white-space: nowrap;
}

.scroll_listing_detail .users-table::-webkit-scrollbar {
    height: 15px;
}

.scroll_listing_detail .users-table::-webkit-scrollbar-track {
    background-color: #F2EDEE;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.scroll_listing_detail .users-table::-webkit-scrollbar-thumb {
    background-color: #414D63;
    border-radius: 999px;
    border: 3px solid #d6d6d6;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.bg_listing_detail {
    margin-bottom: 30px;
}

.main_list_next_page {
    padding: 15px 0;
}

.main_list_next_page ul {
    display: flex;
    align-items: center;
}

.main_list_next_page ul li {
    margin-right: 10px;
}

.img_icon_sec img {
    width: 17px;
    height: fit-content;
    margin: 0;
}

.img_icon_sec a {
    padding: 10px !important;
}

.main_list_next_list a {
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px 14px;
    color: #333;
    border-radius: 5px;
}

.main_list_next_list.active>a {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color);
    color: #fff;
}


/* ========listing detail============ */
/* ==========Query Information========== */
.query_info {
    margin-right: 20px;
}

.query_info img {
    width: 12px;
}

.new_infometion_table {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 10px 20px;
    margin: 20px 0;
}

.new_infometion_table .posts-table {
    width: 100%;
    padding-bottom: 39px;
    margin-bottom: 30px;
}

.table_info_color{
    color: #677D7E;
    font-size: 14px;
}

.info-det-button a {
    padding: 11px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: var(--main-color);
}

.info-det-button {
    text-align: right;
}

.new_infometion_table .posts-table td {
    padding: 10px;
}

.info-det-button a:hover {
    color: #fff;
    background-color: var(--secondary-color);
}

.send_query_list_button {
    text-align: center;
}

.send_query_list_button button {
    padding: 11px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    background: var(--main-color);
    color: #fff;
}

.send_query_list_button a {
    padding: 11px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    background: var(--main-color);
    color: #fff;
}

.right_sec_new {
    float: right;
    padding: 15px 15px 20px;
}

.query_bg_info_giver {
    margin-top: 20px;
    padding: 15px;
    background: #FAF6E6;
}

.query_bg_info_giver .new_infometion_table {
    background: #fff;
    margin: 0;
}

.send_query_list_button button:hover {
    background-color: var(--secondary-color);
}

.file-input-main>[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

.folder_main_update {
    margin-top: 10px;
    height: 38px;
    width: 100%;
    justify-content: space-between !important;
    padding: 0 10px;
    border-radius: 2px;
    background: #f3f4f6;
    color: #ccc;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.save_button {
    padding: 7px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    color: #fff;
    background: #ccc;
    width: 76px;
    margin-top: 12px;
}

.color_place::placeholder {
    color: #a9a9a9 !important;
}

.mai_Add_anoth {
    display: block;
    text-align: right;
}

.query_bg_info_kyc {
    margin-top: 20px;
    padding: 15px;
    background: #FAF6E6;
}

.query_bg_info_kyc .main_section_detail {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px 20px 30px;
}

.input_box_red_flex {
    display: flex;
    flex-wrap: nowrap;
}

.input_box_red_flex h6 {
    color: red;
    padding-right: 35px;
    font-size: 14px;
}

/* ==========Query Information=========== */

/* ============Admin Dashboard=============== */

.nav-user-btn {
    display: flex;
    align-items: center;
}

.nav-user-btn .nav-user-img {
    margin-right: 10px;
}

.nav-user-btn .sr-only-new {
    font-size: 15px;
    color: #333;
}

.sidebar-body-menu .active a {
    background: #fff;
    color: #000;
    border-radius: 4px;
    padding: 2px 5px;

}

.sidebar-body-menu .active a .home_list_icon img {
    filter: invert(1);
}

.stat-cards-info__progress {
    display: flex;
    justify-content: space-between;
}

.daai_icon_img img {
    width: 28px;
}

.cards-color-1 {
    background-color: #f7fdf7;
}

.cards-color-2 {
    background-color: #fffaf6;
}

.stat-cards-color-3 {
    background-color: #fff5f5;
}

.stat-cards-item-bg {
    padding: 24px 19px;
    background-color: #fff;
    -webkit-box-shadow: 0 15px 50px rgba(160, 163, 189, .1);
    box-shadow: 2px 1px 5px 1px #a4a4a494;
    border-radius: 5px;
    height: 100%;
}

.stat-cards-top {
    margin-top: 30px;
}

.pages-table-img {
    display: flex;
    align-items: center;
}

.users-table-info-new {
    height: 48px;
    border-bottom: 1.2px solid #dadbe4;
    font-size: 14px;
}

.users-table-info-new th {
    font-weight: 500;
    padding: 10px;
}

.badge-pending-color {
    color: var(--main-color);
    cursor: pointer;
}

.main-nav-det-tebl {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-nav-det-tebl h4 {

    padding: 5px 0;
    font-size: 18px;
}

.month_this_button {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600 !important;
    background-color: #e6e6e6;
    color: #333 !important;
    border: 1px solid #eee !important;
    text-align: left;
}

.dropdown-filter-new {
    position: unset !important;
    opacity: 1;
    visibility: unset;
    padding: 0;
    position: relative;
}

.main_drop_new_week {
    width: 150px;
    padding: 0;
}

.main_drop_new_week ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.main_drop_new_week ul li {
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
}

.main_week_das p {
    color: #677D7E;
    font-size: 14px;
    padding: 10px 0;
    line-height: 1.5;
}

/*  */
.pieID {
    display: inline-block;
    vertical-align: top;
}

.pie {
    height: 200px;
    width: 200px;
    position: relative;
    margin: 0 30px 30px 0;
}

.pie::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    background: #EEE;
    border-radius: 50%;
    top: 50px;
    left: 50px;
}

.pie::after {
    content: "";
    display: block;
    width: 120px;
    height: 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-shadow: 0 0 3px 4px rgba(0, 0, 0, 0.1);
    margin: 220px auto;

}

.slice {
    position: absolute;
    width: 200px;
    height: 200px;
    clip: rect(0px, 200px, 200px, 100px);
    animation: bake-pie 1s;
}

.slice span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FEDE55;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    clip: rect(0px, 200px, 200px, 100px);
}

.legend {
    list-style-type: none;
    margin: 0;
    background: #FFF;
    padding: 15px;
    font-size: 15px;
}

.legend em {
    font-style: normal;
}

.legend span {
    float: right;
    font-weight: bold;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.main_section_box {
    margin-top: 30px;
}

.dropdown-filter-new::before {
    border-color: transparent transparent transparent;
}

#densityChart {
    height: 300px;
}

#densityChart_next {
    height: 300px;
}

.stat-price {
    font-weight: bold;
    font-size: 40px !important;
    line-height: 1.22;
    color: #000000;
}

.main_detail_new {
    color: var(--main-color);
}

/* ===========Admin Dashboard================ */
/* ===========client_dashboard============ */
.main-nav-det-ship {
    padding: 15px 0;
    margin: 10px 0;
}

.main-nav-det-ship ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.main-nav-det-ship ul li {
    padding: 0 10px;
}

.main-nav-det-ship ul li a {
    background-color: #fffef8;
    -webkit-box-shadow: 0 15px 50px rgba(160, 163, 189, .1);
    box-shadow: 0 1px 2px 1px #70707094;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;
}

.main-nav-det-ship ul li a:hover {
    background: var(--main-color);
    color: #fff;
}

.pieID_1 {
    display: inline-block;
    vertical-align: top;
}

/* ===========client_dashboard============ */

/* ======================= */

.mb-20 {
    margin-bottom: 20px;
}

.main_secdetail_all {
    text-align: right;
    background-color: #ffffff;
    box-shadow: none;
}

.main_secdetail_all a {
    width: 180px;
    text-align: center;
    background: #fff;
}

.main_BE_SB_From {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.input_box_date {
    position: relative;
    margin-right: 10px;
}

.input_box_date input {
    border: 1px solid #F3F4F6;
    padding: 5px 10px;
    width: 100%;
    background-color: #F3F4F6;
    outline: none;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 400;
}

.input_box_date .main_date_new img {
    top: 20px !important;
    position: absolute;
    right: 10px;
}

.main_BE_SB_From label {
    margin-right: 10px;
}

.type_select_new {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.type_select_new label {
    margin-right: 10px;
}

.type_select_new select {
    padding: 11px 0;
    border-radius: 8px;
    font-size: 12px;
    margin-left: 10px;
    border: 1px solid #ccc;
    color: #9f9f9f;
    text-align: left;
    width: 150px;
}

.main_col-table .row {
    padding: 10px 0;
}

.main_listind_table {
    display: flex;
    align-items: center;
}

.main_listind_table span {
    padding: 10px;
    background: #E2FFF3;
    border-radius: 10px;
}

.Sales_list_head {
    padding-left: 10px;
}

.Sales_list_head h5 {
    padding-bottom: 5px;
}

.vailu_numbar_new h4 {
    color: #27AE60;
    font-size: 16px;
    padding-top: 10px;
}

.vailu_numbar_new h6 {
    color: var(--secondary-color);
    font-size: 16px;
    padding-top: 10px;
}

.color_new_make {
    background: #FFF4DE !important;
}

.users-table-detail {
    padding: 26px 0;
    width: 100%;
}

.main_listing_map ul {
    padding: 10px 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.main_listing_map ul li {
    margin-right: 10px;
}

.main_listing_map ul li a span {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: inline-flex;
    margin-right: 10px;
}

.color_map_1 {
    background: #FFA800;
}

.color_map_2 {
    background: #F64E60;
}

.color_map_3 {
    background: #6993FF;
}

.color_map_4 {
    background: #00AB9A;
}

.color_map_5 {
    background: #8950FC;
}

.color_map_6 {
    background: #67728A;
}

.main_listing_map ul li a {
    display: flex;
    align-items: center;
}

.modal-dialog-new {
    max-width: 500px;
    margin: 1.75rem auto;
}

/*  */
/* The Modal (background) */
.modal_Access {
    display: block;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}


.main_contact_close {
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.67;
    border-radius: 8px;
    text-align: center;
    color: #ffffff;
    background-color: var(--main-color);
    width: 200px;
    display: block;
    margin: 12px 0 0 auto;
    cursor: pointer;
}

.main_contact_close:hover {
    background-color: var(--secondary-color);
}

.Access_not_main {
    display: flex;
}

.Access_not {
    width: 100%;
}

.Access_Granted {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.Access_not h4 {
    font-size: 20px;
    color: #000;
}

.Access_not p {
    font-size: 16px;
    color: #333;
}

/* =========Air Export Form================ */
.Air_Export_Form_bg {
    background: #faf8ee;
    padding: 20px;
    margin: 10px 0;
}

.input_box_Air_Export {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
}

.Air_Export_bg_main {
    background: #fff;
    padding: 10px 20px 20px;
    border-radius: 5px;
}

.fill-button-buy a {
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    background: #0095FF;
    color: #fff;
}

.State_Country {
    display: flex;
    align-items: center;
}

.State_Country select {
    margin-right: 10px;
}

.add-Company-button {
    color: var(--main-color);
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #ccc;
    width: 170px;
    float: right;
    text-align: center;
}

.table_detail_tariff .posts-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
}

.users-table-tariff {
    background-color: #F0F0F0;
    color: #000;
    box-shadow: 0px 0px 0px 1px #b4b4b494;
}

.users-table-tariff {
    height: 48px;
    border-bottom: 1.2px solid #dadbe4;
    font-size: 14px;
}

.table_detail_tariff .posts-table td,
.users-table-tariff th {
    padding: 8px;
}

.main_table_row_new {
    box-shadow: 0px 0px 0px 1px #b4b4b494;
}

.flex_detail_main {
    display: flex;
    align-items: flex-end;
}

.table_detail_tariff {
    width: 100%;
}

.box_button_main {
    position: relative;
    top: -14px;
}

.main-Export-button {
    text-align: right;
    margin-top: 15px;
}

.w-100-new {
    width: 100%;
}

.box_button_main-24 {
    position: relative;
    top: -24px;
}

/* ======Modal======= */
.detail_popup_width {
    width: 100%;
    max-width: 1200px;
}

.detail_con_popup_main {
    padding: 20px;
}

.btn-close-new-section {
    position: absolute;
    z-index: 9;
    top: -24px;
    right: -24px;
    background: var(--main-color);
    height: 40px;
    width: 40px;
    opacity: 1;
    color: #fff;
    border-radius: 100%;
}

.btn-close-new-section button {
    position: relative;
    left: 8px;
    top: 8px;
}

.detail_popup_width .modal-content {
    max-width: 100%;
    padding: 0;
}

.input_popup_rate input {
    background: #fff;
    border: 1px solid #c3c3c3 !important;
}

.input_popup_rate input::placeholder {
    color: #ccc;
}

.n_detail_comp th,
.n_detail_comp td {
    padding: 5px !important;
    font-size: 14px;
}

.info-det-button-add {
    text-align: right;
}

.info-det-button-add a {
    padding: 7px 13px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    background: #ccc;
}

.info-det-button-add a:hover {
    color: #fff;
    background: var(--main-color);
}

.main_icon_click a {
    margin-right: 5px;
}

.main_icon_click {
    display: flex;
}

.view_in_color {
    color: #117B34;
}

/* ======Modal======= */
.cat-sub-menu.visible a:active {
    color: #000;
}

/* ==========Air Export Form============ */
.users-table-tarif-main th {
    background-color: #F0F0F0;
    color: #000;
    padding: 6px;
    box-shadow: 1px 0px 1px 1px #f0f0f0;
}

.bg_none_table {
    background-color: transparent !important;
    border: 0;
    box-shadow: none !important;
}

.main_table_row_new td {
    box-shadow: 0px 0px 0 1px #f0f0f0;
}

.bg_table_new {
    background: #68D2E8;
}

.input_box_section_bg textarea {
    background: #fff;
}

.color_main_alex {
    color: #677D7E;
}

.dr_right {
    justify-content: right;
}

.bg_left_color {
    background-color: #f2f2f2;
}

.Summary_Sender_bg {
    margin: 8px 0;
    padding: 15px;
    border-radius: 10px;
}

.bg_right_color {
    background-color: #f2f2fc;
}

.bg_left_color h4 {
    color: var(--main-color);
}

.bg_right_color h4 {
    color: #5E57D8;
}

.Summary_Sender_bg h4 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 14px;
}

.Summary_Sender_bg p {
    font-size: 14px;
    line-height: 1.6;
}

.mar_none_Summary {
    margin: 0 10px;
}

.text-left {
    text-align: left !important;
}

.button-det-list a:hover {
    background: var(--secondary-color);
}

.none_border {
    border: 0px solid #ccc;
}

.new_infometion_table .posts-table td p {
    line-height: 1.6;
}

.mar_10 {
    margin-bottom: 10px;
}

.send_query_list_button a:hover {
    color: #fff;
    background-color: var(--secondary-color);
}

.padd_left_inherit {
    padding-left: initial !important;
}


.ph-Dashboard {
    background-image: url(../public/img/dashboard.png);
    width: 22px;
    height: 22px;
    background-size: cover;
}

.ph-Pricing {
    background-image: url(../public/img/Billing.png);
    width: 22px;
    height: 22px;
    background-size: cover;
}

.ph-Sales {
    background-image: url(../public/img/Sales_CRM.png);
    width: 22px;
    height: 22px;
    background-size: cover;
}



.user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.user-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 6px;
    background: #fff;
}

.user-details .title,
.menu .title {
    font-size: 10px;
    font-weight: 500;
    color: #757575;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.user-details .name {
    font-size: 14px;
    font-weight: BOLD;
    color: #fff;
    width: 145px;
}

.nav {
    flex: 1;
}

.padd_detail_top {
    padding-top: 10px;
}

.menu ul li {
    position: relative;
    list-style: none;
    margin-bottom: 5px;
}


.menu ul li .icon {
    font-size: 20px;
}

.menu ul li .text {
    flex: 1;
}


.menu .sub-menu {
    display: none;
    margin-left: 20px;
    padding-left: 20px;
    padding-top: 5px;
    border-left: 1px solid #f6f6f6;
}

.menu .sub-menu li a {
    padding: 10px 8px;
    font-size: 16px;
}

.menu:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #f6f6f6;
}

.menu-btn:hover i {
    color: #000;
}

.menu-btn i {
    transition: all 0.3s;
}

.sidebar.active {
    width: 92px;
}

.sidebar.active .menu-btn i {
    transform: rotate(180deg);
}

.sidebar.active .user-details {
    display: none;
}

.sidebar.active .menu .title {
    text-align: center;
}

.sidebar.active .menu ul li .arrow {
    display: none;
}

.sidebar.active .menu>ul>li>a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar.active .menu>ul>li>a .text {
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
}

.sidebar.active .menu>ul>li>a .text::after {
    content: "";
    position: absolute;
    left: -5px;
    top: 20%;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #000;
    transform: rotate(45deg);
    z-index: -1;
}

.sidebar.active .menu>ul>li>a:hover .text {
    left: 50px;
    opacity: 1;
    visibility: visible;
}

.sidebar.active .menu .sub-menu {
    position: absolute;
    top: 0;
    left: 20px;
    width: 240px;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #005059;
    background-color: #005059;
    box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.container.main-nav {
    max-width: 100%;
}

.page-flex {
    width: 100%;
}

/*  */
/* ===============progress bar==================== */




#checkout-progress {
    width: 85%;
    margin: 0px auto;
    font-size: 2.5em;
    font-weight: 900;
    position: relative;
}

#checkout-progress:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 4px;
    width: 100%;
    background-color: #ccc;
    -webkit-transform: translateY(-50%) perspective(1000px);
    transform: translateY(-50%) perspective(1000px);
}

#checkout-progress:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 4px;
    width: 100%;
    background-color: var(--main-color);
    -webkit-transform: scaleX(0) translateY(-50%) perspective(1000px);
    transform: scaleX(0) translateY(-50%) perspective(1000px);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#checkout-progress.step-2:after {
    -webkit-transform: scaleX(0.333) translateY(-50%) perspective(1000px);
    transform: scaleX(0.333) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-3:after {
    -webkit-transform: scaleX(0.666) translateY(-50%) perspective(1000px);
    transform: scaleX(0.666) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-4:after {
    -webkit-transform: scaleX(1) translateY(-50%) perspective(1000px);
    transform: scaleX(1) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-5:after {
    -webkit-transform: scaleX(1) translateY(-50%) perspective(1000px);
    transform: scaleX(1) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-6:after {
    -webkit-transform: scaleX(1) translateY(-50%) perspective(1000px);
    transform: scaleX(1) translateY(-50%) perspective(1000px);
}

#checkout-progress .progress-bar {
    width: 100%;
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
}

.progress-bar {
    flex-direction: unset;
    overflow: unset;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    transition: width .6s ease;
}

#checkout-progress .progress-bar .step {
    z-index: 2;
    position: relative;
}

#checkout-progress .progress-bar .step .step-label {
    position: absolute;
    top: calc(100% + 25px);
    left: 50%;
    -webkit-transform: translateX(-50%) perspective(1000px);
    transform: translateX(-50%) perspective(1000px);
    white-space: nowrap;
    font-size: 0.4em;
    font-weight: 600;
    color: #ccc;
    transition: 0.3s ease;
}

#checkout-progress .progress-bar .step span {
    color: #ccc;
    transition: 0.3s ease;
    display: block;
    font-size: 24px;
    -webkit-transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
    transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
}

#checkout-progress .progress-bar .step .fa-check {
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
    transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
}

#checkout-progress .progress-bar .step.active span {
    font-size: 24px;
}

#checkout-progress .progress-bar .step.active span,
#checkout-progress .progress-bar .step.active .step-label {
    color: var(--main-color);
}

#checkout-progress .progress-bar .step.valid .fa-check {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
    transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
}

#checkout-progress .progress-bar .step.valid span {
    color: #fff;
    -webkit-transform: translate3d(0, 0, 0) scale(2) perspective(1000px);
    transform: translate3d(0, 0, 0) scale(2) perspective(1000px);
    font-size: 12px;
}

#checkout-progress .progress-bar .step.valid .step-label {
    color: var(--main-color) !important;
}

#checkout-progress .progress-bar .step:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) perspective(1000px);
    transform: translate(-50%, -50%) perspective(1000px);
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    border: 5px solid #ccc;
    transition: 0.3s ease;
}

#checkout-progress .progress-bar .step.active:after {
    border: 5px solid var(--main-color);
}

#checkout-progress .progress-bar .step.valid:after {
    background-color: var(--main-color);
    border: 5px solid var(--main-color);
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: 30px auto 0px;
    border-top: 2px solid #d4d4d4;
    padding-top: 20px;
}

.button-container .btn {
    display: inline-block;
    background-color: var(--main-color);
    color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
    border: 3px solid var(--main-color);
    transition: 0.3s ease;
    cursor: pointer;
    text-align: center;
}

.button-container .btn:hover {
    background-color: transparent;
    color: #2C3E50;
    -webkit-transform: scale(1.02) perspective(1000px);
    transform: scale(1.02) perspective(1000px);
}




/* ===============progress bar===================== */
@media (min-width:1200px) {
    /* .sidebar {
        width: 20%;
    } */

    .main-wrapper {
        width: 80%;
    }

    .main-wrapper-add {
        width: 100%;
    }

}

/* ========section end========== */
@media (min-width:576px) {

    .container {
        max-width: 540px
    }

    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-sm-1,
    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3,
    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5,
    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7,
    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-10,
    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11,
    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }

    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-md-1,
    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3,
    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5,
    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7,
    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-10,
    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11,
    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }

    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-lg-1,
    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3,
    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5,
    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7,
    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-10,
    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11,
    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }

    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xl-1,
    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3,
    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5,
    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7,
    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-10,
    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11,
    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .sidebar.hidden {
        width: 64px;
        padding-right: 4px;
        padding-left: 4px
    }

    .sidebar.hidden .logo-wrapper {
        display: none
    }

    .sidebar.hidden * {
        font-size: 0
    }

    .sidebar.hidden .sidebar-head {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 20px;
        padding-bottom: 40px
    }

    /* .main-nav .sidebar-toggle,
    .sidebar.hidden .category__btn {
        display: none
    } */

    .sidebar.hidden .sidebar-body-menu a {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px
    }

    .sidebar.hidden .sidebar-body-menu a::after {
        right: -4px
    }

    .sidebar.hidden .sidebar-body-menu a>.icon {
        margin-right: 0
    }

    .sidebar.hidden .msg-counter {
        right: 10px;
        top: 30%
    }

    .sidebar.hidden .sidebar-user {
        width: 52px;
        height: 53px;
        margin-right: auto;
        margin-left: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .sidebar.hidden .sidebar-user-img {
        width: 40px;
        height: 40px;
        margin-right: 0
    }

    .sidebar.hidden .sidebar-user-img img {
        height: 100%;
        width: 100%
    }
}

@media (min-width:1520px) {
    .container {
        max-width: 100%;
        width: 100%;
    }


    .col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xxl-1,
    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xxl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxl-3,
    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xxl-5,
    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxl-7,
    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xxl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xxl-10,
    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xxl-11,
    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .customers-wrapper {
        height: auto
    }

    .customers-wrapper img {
        display: none
    }
}

@media (max-width:1300px) {
    .send_query_list_button a {
        padding: 11px 10px;
        font-size: 14px;
    }

    .upgrade-btn {
        padding: 10px 7px
    }

    .upgrade-btn svg {
        margin-left: 5px
    }

    .sort-bar .search-wrapper input {
        width: 174px
    }

    .categories-sort-bar .search-wrapper input {
        width: 271px
    }

    .chart {
        height: auto;
        max-height: 370px
    }
}

@media (max-width:1199.98px) {
    .main-wrapper {
        width: 100%;
    }

    .sidebar {
        overflow-y: initial !important;
        position: fixed !important;
        z-index: 999;

    }

    .sidebar.hidden {
        left: 0;
    }

    .main-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        /* -ms-flex-direction: row-reverse;
        flex-direction: row-reverse */
    }

    .sort-bar {
        padding: 20px
    }

    .sort-bar .select {
        margin-right: 10px
    }
}

@media (max-width:991.98px) {
    .search-wrapper input {
        width: 100%;
    }

    .main-title {
        font-size: 18px !important;
    }

    .send_query_list_button {
        margin: 10px 0;
    }

    .main_listing_button {
        overflow-y: scroll;
    }

    .main_listing_button ul li a {
        white-space: nowrap;
    }

    .sort-bar-start {
        width: 100%;
        margin-bottom: 10px
    }

    .sort-bar .search-wrapper,
    .sort-bar .search-wrapper input {
        width: 100%
    }

    .sort-bar .select {
        margin-right: 0;
        min-width: auto;
        width: 100%
    }

    .sort-bar-end {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%
    }

    .sort-bar-end .select-wrapper {
        width: 24%
    }

    .customers-wrapper {
        height: auto
    }

    .customers-wrapper img {
        display: none
    }
}

@media (max-width:767.98px) {
    .border-right-main{
        border-right: none !important;
    }
    /* .main-nav-start{
        display: none;
    } */
    .custom-resume-stepper {
        grid-template-columns: 0fr 6fr;
    }

    .custom-resume-stepper .card-text {
        font-size: 14px;
    }

    .custom-resume-stepper .MuiStepper-vertical {
        width: 30px;
    }

    .custom-resume-stepper .MuiStepLabel-label {
        display: none;
    }

    .stat-cards>div {
        margin: 10px 0;
    }

    .stat-cards-top .col-md-6 {
        margin: 10px 0;
    }

    .send_query_list_button {
        margin: 10px 0;
    }

    .input_box_filet input {
        margin-bottom: 10px;
    }

    .main-nav .nav-user-img {
        overflow: unset;
        width: 40px;
        height: 40px;
    }

    .users-table__checkbox {
        width: 100%;
        margin-top: 10px;
    }

    /* .main-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    } */

    .main-nav-start,
    .main-nav-start input {
        width: 100%
    }

    .main-nav-end {
        width: 100%;
        /* display: grid !important;
        grid-template-columns: 1fr 8fr 5fr 1fr; */
    }

    .sort-bar {
        padding-bottom: 10px
    }

    .sort-bar-end .select-wrapper {
        width: 48%;
        margin-bottom: 10px
    }

    /* =========progresh bar========= */
    #checkout-progress {
        font-size: 1.5em;
    }

    #checkout-progress .progress-bar .step:after {
        width: 40px;
        height: 40px;
    }

    #checkout-progress .progress-bar .step .step-label {
        top: calc(100% + 15px);
    }

    .button-container .btn {
        width: 100%;
        margin-bottom: 15px;
    }

    /* =========progresh bar========= */

}

@media (max-width:575.98px) {
    .stat-cards-item-bg {
        margin-bottom: 10px;
    }

    .info-det-button a {
        padding: 10px 15px;
        width: 160px;
        display: block;
        text-align: center;
    }

    .main_select_photo ul {
        flex-wrap: wrap;
        justify-content: center;

    }

    .custom-resume-stepper .btn-outline-primary {
        padding: 5px 12px !important;
        font-size: 14px !important;
    }

    .custom-resume-stepper .font-semibold {
        font-size: 18px;
    }

    .main-title {
        text-align: center;
    }

    .main_select_photo ul li {
        margin-bottom: 5px;
    }

    .main-btns-wrapper {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: end
    }

    .main-btns-wrapper button,
    .main-btns-wrapper select {
        width: 48%;
        margin-right: 0;
        margin-bottom: 15px;
        font-size: 14px
    }

    .sort-bar {
        padding: 10px 10px 5px
    }

    .main {
        margin-bottom: 0
    }

    .main-nav-end {
        /* margin-bottom: 20px; */
        justify-content: end !important;

    }

    .delivery-box {
        width: 48%;
    }

}

@media (max-width:480.98px) {
    .main-title {
        font-size: 20px
    }

    .primary-default-btn {
        padding: 8px 15px
    }

    .sort-bar-end .select-wrapper {
        width: 49%
    }

    .main_listing_button {
        overflow-y: scroll;
    }

    .main_listing_button ul li a {
        width: 125px;
        display: flex;
        justify-content: center;
    }

    .main-nav-end {
        /* flex-wrap: wrap;
        justify-content: center; */
        margin-bottom: 0;
    }

    .delivery-box {
        width: 100%;
    }
}

@media (max-width:420px) {

.sidebar{
    width: 320px !important;
}
    .custom-stepper-button {
        display: block !important;
    }

    .custom-stepper-button .prev-btn-resume,
    .custom-stepper-button .next-btn-resume,
    .custom-stepper-button .work-details .prev-btn-resume,
    .work-details .next-btn-resume,
    .project-details .prev-btn-resume,
    .project-details .next-btn-resume,
    .education-details .prev-btn-resume,
    .education-details .next-btn-resume,
    .certificate-details .prev-btn-resume,
    .certificate-details .next-btn-resume,
    .accomplishments-cv-builder .prev-btn-resume,
    .accomplishments-cv-builder .next-btn-resume {
        margin: 10px 0px;
        width: 100%;
    }

    .custom-stepper-1 {
        flex-direction: column;
        justify-content: strat !important;
        align-items: start !important;
        gap: 10px;
    }
}

/*# sourceMappingURL=style.min.css.map */